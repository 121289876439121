.background-line-login-page {
    background-color: #f1f1f1;
    height: 100vh;
    margin: 0px;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.segment-line-login-page {
    margin: 1% !important;
    height: calc(100vh - (100vh * 12 / 100));
    box-shadow: 0px 1px 40px #dcdcdc !important;
    border-radius: 20px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
