.demo-app-top {
    margin: 0 0 3em;
    display: block;
}

.demo-app-calendar {
    margin: 0 auto;
    max-width: 900px;
}

.fc-event-title.fc-sticky,
.fc-list-event.fc-event.fc-event-start.fc-event-end.fc-event-past {
    cursor: pointer;
}

.fc-list-event-time {
    display: none;
}

@media only screen and (max-width: 600px) {
    .fc-toolbar,
    .fc-header-toolbar {
        display: grid;
    }
}
