.label-link {
    color: #53A6D8;
     cursor: pointer
}

.image-one-platform {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 30px
}

.segment-selected {
    border: 3px solid #4d648d !important;
    border-radius: 10px !important;
    padding: 10px !important;
    cursor: pointer;
}

.segment-unselected {
    border-radius: 10px !important;
    padding: 10px !important;
    cursor: pointer;
}


.segment-error {
    border: 3px solid #E0B4B4 !important;
    border-radius: 10px !important;
    padding: 10px !important;
    cursor: pointer;
    background-color: #FFF6F6 !important;
}