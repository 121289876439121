.background-login-page {
	background-color: #f1f1f1;
	height: 100vh;
	margin: 0px;
	min-height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.segment-login-page {
	margin: 2% 5% !important;
	height: calc(100vh - (100vh*12/100));
	box-shadow: 0px 1px 40px #dcdcdc !important;
	border-radius: 20px !important;
	display: flex;
	align-items: center;
	justify-content: center;
}
.header-segment-login-page {
	height: calc(100vh - (100vh*70/100));
    display: flex;
	align-items: flex-end;
    justify-content: center;
}
.body-segment-login-page {
	height: calc(100vh - (100vh*50/100));
}
.footer-segment-login-page {
	height: calc(100vh - (100vh*95/100));
}
