.selected-prefix {
    display: flex;
    border: 4px solid #4d648d !important;
    border-radius: 10px !important;
    padding: 15px !important;
    margin-top: 5px !important;
    cursor: pointer;
}

.unselected-prefix {
    display: flex;
    padding: 15px !important;
    margin-top: 5px !important;
    cursor: pointer;
}

.error-prefix {
    display: flex;
    border: 4px solid #E0B4B4 !important;
    border-radius: 10px !important;
    padding: 15px !important;
    margin-top: 5px !important;
    cursor: pointer;
    background-color: #FFF6F6 !important;
}