body,
h1,
h2,
h3,
h4,
h5,
h6,
input,
a,
p,
link,
textArea,
button,
header,
tspan,
text {
    font-family: 'Kanit' !important;
}

@font-face {
    font-family: 'Kanit';
    src: url('../../assets/fonts/Kanit-Light.ttf');
}

@media only screen and (min-width: 600px) {
    .background-container {
        background-color: #f1f1f1;
        padding-right: 15px;
        padding-left: 15px;
        padding-top: 15px;
        padding-bottom: 15px;
        min-height: calc(100vh - 65px);
    }
}

@media only screen and (max-width: 450px) {
    .background-container {
        background-color: #f1f1f1;
        padding-top: 30px;
        min-height: calc(100vh - 65px);
    }
}
.background-container-no-sidebar {
    background-color: #f1f1f1;
        padding-top: 30px;
        min-height: 100vh
}
.ui.sidebar {
    overflow: visible !important;
}

.ui.modal > .actions {
    display: flex;
    padding: 0rem 0rem 0 !important;
    border-top: 0px solid rgba(34, 36, 38, 0.15);
}

.ui.header,
.ui.dropdown,
.ui.menu,
.ui.button,
.ui.modal,
.header,
.title {
    font-family: 'Kanit' !important;
}

/* input,
.ui.icon.input > input,
.ui.input,
dropdown,
.ui.fluid.dropdown,
.ui.selection.dropdown,
.ui.form textarea {
	border-radius: 8px !important;
} */

.header-input-form {
    font-size: 1rem !important;
    color: #425679 !important;
    border-color: #425679 !important;
    font-weight: bold;
}

.ui.radio.checkbox input:checked ~ .box:after,
.ui.radio.checkbox input:checked ~ label:after {
    background-color: #2d83b6 !important;
}

.ui.radio.checkbox input:disabled ~ .box:after,
.ui.radio.checkbox input:disabled ~ label:after {
    background-color: #8e8f92 !important;
}

.table-header {
    border-radius: 10px !important;
    position: sticky;
    /* top: 60px !important; */
    z-index: 800;
}

.table-header thead {
    border-right: hidden !important;
    border-bottom: hidden !important;
    filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px);
}

.table-header th {
    background-color: rgb(40, 54, 85) !important;
    color: white !important;
    border-color: white !important;
    border-bottom: hidden !important;
}

.table-header th:first-child {
    border-top-left-radius: 10px !important;
    /* border-bottom-left-radius: 10px !important; */
}

.table-header th:last-child {
    border-top-right-radius: 10px !important;
    /* border-bottom-right-radius: 10px !important; */
}

.table-body {
    border-radius: 10px !important;
    border-collapse: separate !important;
    border-spacing: 0px 10px !important;
    background-color: rgb(241, 241, 241) !important;
    border: none !important;
    color: #293757;
}

.table-body tbody {
    filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px);
}

.table-body td {
    border: 1px solid #f5f5f5 !important;
    box-sizing: border-box !important;
    background-color: white !important;
}

/* .table-body td:first-child {
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
}

.table-body td:last-child {
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
} */

.table-merge-cell {
    border: none;
    color: #293757;
}
.table-merge-cell tbody {
    border: 1px solid #f5f5f5 !important;
    filter: none;
}
/* 
.table-body-merge {
    margin-top: 10px;
    border-radius: 10px !important;
    border-collapse: separate !important;
    border-spacing: 0px 10px !important;
    background-color: rgb(241, 241, 241) !important;
    border: none !important;
    color: #293757;
} */

.table-body-merge {
    border-collapse: separate !important;
    margin-top: 15px !important;
    background-color: rgb(241, 241, 241) !important;
    border: none !important;
    color: #4d648d;
}
.table-body-merge tbody {
    filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px);
}

.table-body-merge td {
    border: 1px solid #f5f5f5 !important;
    box-sizing: border-box !important;
    background-color: white !important;
}

.card-main-page {
    border-radius: 16px;
    cursor: pointer;
    filter: drop-shadow(0px 15px 25px rgba(72, 79, 95, 0.5));
    height: 450px;
}

.card-main-access {
    border-radius: 16px;
    cursor: pointer;
    filter: drop-shadow(0px 5px 15px rgba(72, 79, 95, 0.5));
}

.card-main-page-image {
    background: linear-gradient(110.03deg, rgb(119, 175, 208) 3.14%, rgb(40, 54, 85) 85.69%), rgb(188, 209, 255);
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px 16px 0px 0px !important;
}

.card-main-page-link {
    align-self: flex-end;
    text-align: right;
    margin: 20px;
    cursor: pointer;
    color: rgb(0, 122, 233);
}

.ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: #75b9a5 !important;
}

.ui.toggle.checkbox input:checked ~ label:before {
    background-color: #75b9a5 !important;
}

a {
    color: #040608;
    text-decoration: none;
}

.card-system {
    color: #040608;
    text-decoration: none;
}
/* .table-body-merge tr:first-child td:first-child {
/*.table-body-merge tr:first-child td:first-child {
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
}
.table-body-merge tr:first-child td:last-child {
	border-top-right-radius: 10px;
}

.table-body-merge tr:last-child td:last-child {
	border-bottom-right-radius: 10px;
} */

/* ::-webkit-scrollbar {
	width: 10px;
}

::-webkit-scrollbar-track {
	background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
	background: rgb(40, 54, 85);
}

::-webkit-scrollbar-thumb:hover {
	background: #4d648d;
}*/

.card-menu-municipalities {
    filter: drop-shadow(0px 24px 38px rgba(0, 0, 0, 0.14)), drop-shadow(0px 9px 46px rgba(0, 0, 0, 0.12)),
        drop-shadow(0px 11px 15px rgba(0, 0, 0, 0.2));
    border-radius: 18px !important;
    margin-top: 15px !important;
}

.card-menu-municipalities.ui.card > .extra {
    background-color: #293757;
    color: #ffffff;
    text-align: center;
    border-radius: 0px 0px 18px 18px !important;
    cursor: pointer;
}
/* 
.highcharts-container {
    position: absolute !important;
} */