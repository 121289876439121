.custom-modal {
	margin-top: 30px !important;
	border-radius: 20px !important;
	padding: 0px !important;
	border-top-width: 0px !important;
	border-left-width: 0px !important;
	border-right-width: 0px !important;
	border-bottom-width: 0px !important;
}

.header-modal {
	border-top-right-radius: 15px;
	border-top-left-radius: 15px;
	background-color: #283655;
	font-weight: bold;
	color: white;
	padding: 15px;
}
